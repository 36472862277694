<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>
      
      <router-link v-if="is_API_loaded" :to="{ name: 'new-product' }">
        <vs-button size="large"><span class="font-medium">新增商品</span></vs-button>
      </router-link>

      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>
    
    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="px-4 grid grid-cols-2 gap-2 lg:grid-cols-8">
        <vs-input class="lg:col-span-2" v-model="searchTitle" @blur="search" @keypress.enter="search" placeholder="搜尋商品" />

        <vs-select multiple filter placeholder="篩選狀態" v-model="filtered" @change="statusOptions">
          <vs-option label="已公開" value="1">已公開</vs-option>
          <vs-option label="已隱藏" value="0">已隱藏</vs-option>
        </vs-select>

        <vs-tooltip bottom not-hover shadow v-model="inventoryTooltip">
          <button
            @click="inventoryTooltip = !inventoryTooltip"
            class="flex flex-wrap items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden w-full h-10"
          >
            <div style="color: rgba(156, 163, 175, var(--tw-text-opacity))">
              <span>篩選庫存數</span
              ><span v-if="$route.query.inventory && $route.query.inventory >= 0"> &lt;= {{ $route.query.inventory }}</span>
            </div>
          </button>

          <template #tooltip>
            <div class="flex flex-col items-center justify-center space-y-2">
              <p>庫存數小於等於</p>
              <div class="flex items-center justify-center space-x-2">
                <vs-button transparent color="success" @click="filteredInventory--" :disabled="filteredInventory <= 0" class="text-base"
                  >-</vs-button
                >
                <input type="number" placeholder="0" class="text-center w-6" v-model.number="filteredInventory" @input="inventoryChange" />
                <vs-button transparent color="success" @click="filteredInventory++" class="text-base">+</vs-button>
              </div>
              <div class="flex items-center justify-center space-x-2">
                <vs-button @click="inventoryCancel" transparent color="danger">清除</vs-button>
                <vs-button @click="inventoryConfirm" transparent color="success">確定</vs-button>
              </div>
            </div>
          </template>
        </vs-tooltip>

        <vs-select multiple filter placeholder="篩選到貨通知狀態" v-model="filteredInstockStatus" @change="instock_statusOptions">
          <vs-option label="尚未到貨" value="0">尚未通知</vs-option>
          <vs-option label="已到貨" value="1">已通知</vs-option>
        </vs-select>

        <vs-select
          v-if="collections.length > 0"
          collapse-chips
          multiple
          filter
          placeholder="篩選分類"
          v-model="filteredCollections"
          @change="getProductsList(['collections', filteredCollections.join()])"
        >
          <vs-option v-for="item in _collections" :key="item.sptno" :label="item.title" :value="item.sptno">{{ item.title }}</vs-option>
        </vs-select>
        <vs-select
          :disabled="tags.length === 0"
          collapse-chips
          multiple
          filter
          placeholder="篩選標籤"
          v-model="filteredTags"
          @change="getProductsList(['tags', filteredTags.join()])"
        >
          <vs-option v-for="item in tags" :key="item.sptno" :label="item.title" :value="item.sptno">{{ item.title }}</vs-option>
        </vs-select>
        <vs-select
          :disabled="vendors.length === 0"
          collapse-chips
          multiple
          filter
          placeholder="篩選供應商"
          v-model="filteredVendors"
          @change="getProductsList(['vendors', filteredVendors.join()])"
        >
          <vs-option v-for="item in vendors" :key="item.spmno" :label="item.title" :value="item.spmno">{{ item.title }}</vs-option>
        </vs-select>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min xl:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ productsList.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="batchPublic(1)" class="px-4 hover:bg-gray-background font-medium">公開</button>
                <button @click="batchPublic(0)" class="px-4 hover:bg-gray-background font-medium">隱藏</button>
                <button @click="instockNotice_dialog = true" class="px-4 hover:bg-gray-background font-medium">到貨通知</button>
                <button @click="delete_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title ml-14">標題</span>
              <span class="section2">狀態</span>
              <span class="section1">庫存</span>
              <span class="section2">到貨通知</span>
              <span class="section1">分類</span>
              <span class="section1">標籤</span>
              <span class="section1">供應商</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min xl:w-full">
          <div v-for="item in productsList" :key="item.spno">
            <router-link
              :to="{
                name: 'edit-product',
                params: {
                  id: item.spno,
                },
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
              <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.spno"></vs-checkbox>
              <div class="title_section">
                <div class="w-14">
                  <div class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden">
                    <img v-if="item.img" :src="item.img" class="w-full h-full object-cover" />
                    <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                  </div>
                </div>
                <div class="flex flex-col title">
                  <span class="text-limit-1">{{ item.title }}</span>
                </div>
                <div class="section2">
                  <span v-if="item.isrtime" class="bg-primary text-white rounded-full px-3 py-1">已公開</span>
                  <span v-else-if="!item.isrtime && isSchedule(item.rtime)" class="bg-warning text-white rounded-full px-3 py-1"
                    >排程中</span
                  >
                  <span v-else class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">已隱藏</span>
                </div>
                <div v-if="item.stock_track" class="section1">{{ item.snum }}</div>
                <div v-else class="section1">未追蹤庫存</div>
                <div class="section2">
                  <span v-if="item.instock_times > 0" class="bg-primary text-white rounded-full px-3 py-1">已通知({{item.instock_times}}次)</span>
                  <span v-else class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">尚未通知</span>
                </div>
                 <!-- 分類 -->
                 <div v-if="item.type.split(',').length > 1" class="section1" @click.stop="">
                  <vs-tooltip primary class="w-fit flex items-center justify-center space-x-1">
                    <span>{{ tagFilter(item.type, 1) }}</span>
                    <div class="bg-gray-divide w-6 h-6 rounded-full text-white flex items-center justify-center">
                      {{ tagFilter(item.type, 2) }}
                    </div>
                    <template #tooltip>{{ tagFilter(item.type, 3) }}</template>
                  </vs-tooltip>
                </div>
                <div v-else class="section1">
                  {{ item.type }}
                </div>
                <!-- 標籤 -->
                <div v-if="item.tag.split(',').length > 1" class="section1" @click.stop="">
                  <vs-tooltip primary class="w-fit flex items-center justify-center space-x-1">
                    <span>{{ tagFilter(item.tag, 1) }}</span>
                    <div class="bg-gray-divide w-6 h-6 rounded-full text-white flex items-center justify-center">
                      {{ tagFilter(item.tag, 2) }}
                    </div>
                    <template #tooltip>{{ tagFilter(item.tag, 3) }}</template>
                  </vs-tooltip>
                </div>
                <div v-else class="section1">
                  {{ item.tag }}
                </div>
                <!-- 供應商 -->
                <div v-if="item.manu.split(',').length > 1" class="section1" @click.stop="">
                  <vs-tooltip primary class="w-fit flex items-center justify-center space-x-1">
                    <span>{{ tagFilter(item.manu, 1) }}</span>
                    <div class="bg-gray-divide w-6 h-6 rounded-full text-white flex items-center justify-center">
                      {{ tagFilter(item.manu, 2) }}
                    </div>
                    <template #tooltip>{{ tagFilter(item.manu, 3) }}</template>
                  </vs-tooltip>
                </div>
                <div v-else class="section1">
                  {{ item.manu }}
                </div>
              </div>

              <!-- 預覽分頁按鈕 -->
              <a
                @click.stop=""
                :href="item.mpages"
                target="_blank"
                class="
                  hidden
                  lg:block
                  absolute
                  right-3
                  top-1/2
                  transform
                  -translate-y-1/2
                  transition-all
                  opacity-0
                  hover:bg-gray-divide
                  group-hover:opacity-100 group-hover:visible
                  invisible
                  rounded-lg
                "
              >
                <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
              </a>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>
    
    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除商品 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆商品？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的商品無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 到貨商品 dialog -->
    <vs-dialog overflow-hidden blur v-model="instockNotice_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">確定通知 {{ selected.length }} 筆商品？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">通知的商品無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="instockNotice_dialog = false">取消</vs-button>
          <vs-button @click="bathInstock" size="large" transparent color="danger" ref="instockBtn">確定</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'products',
  data() {
    return {
      is_API_loaded: false,
      currentPage: 1, // 當前頁數
      searchTitle: '', // 搜尋標題input
      searchTitleBak: '',
      filtered: [], // 篩選發布狀態
      filteredInstockStatus: [], //篩選出貨狀態
      productsList: [], // 商品列表
      pagination: [], // 頁數資訊
      selected: [], // 多選checkbox
      selectAll: false,
      indeterminate: false,
      delete_dialog: false,
      instockNotice_dialog: false,
      collections: [], // 分類列表
      filteredCollections: [], // 篩選分類
      vendors: [], // 供應商列表
      filteredVendors: [], // 篩選供應商
      tags: [], // 標籤列表
      filteredTags: [], // 篩選標籤
      inventoryTooltip: false, // 篩選庫存tooltip
      filteredInventory: '', // 篩選庫存數 (小於等於, -1無搜尋)
      defaultQuery: {}, // 預設$route.query
    }
  },
  computed: {
    _collections() {
      let flatArr = []
      this.collections.forEach((arr1) => {
        flatArr.push({
          sptno: arr1.sptno,
          title: arr1.title,
        })

        arr1.arr.forEach((arr2) => {
          flatArr.push({
            sptno: arr2.sptno,
            title: arr2.title,
          })

          arr2.arr.forEach((arr3) => {
            flatArr.push({
              sptno: arr3.sptno,
              title: arr3.title,
            })
          })
        })
      })

      return flatArr
    },
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  async created() {
    let query = this.$route.query
    this.defaultQuery = query
    this.searchTitle = query.query ? query.query : ''
    this.searchTitleBak = query.query ? query.query : ''
    this.filteredInventory = query.inventory ? query.inventory : ''
    if(query.isrtime != -1) this.filtered = query.isrtime ? query.isrtime : ['-1']
    else this.filtered = ['-1']
    if(query.instock_status != -1) this.filteredInstock = query.instock_status ? query.instock_status : ['-1']
    else this.filteredInstock = ['-1']
    this.filteredCollections = query.collections ? query.collections.split(',') : []
    this.filteredTags = query.tags ? query.tags.split(',') : []
    this.filteredVendors = query.vendors ? query.vendors.split(',') : []
    this.currentPage = query.page ? parseInt(query.page) : 1

    const res = await Promise.all([this.getVendorList(), this.getCollections(), this.getTagList(), this.getProductsList()])

    // res會是陣列，若都為true，才顯示頁面
    if (res.every((e) => e === true)) {
      this.is_API_loaded = true
    }
  },
  methods: {
    // 判斷是不是排程中
    isSchedule(rtime) {
      let time = new Date(rtime.replace(new RegExp('-', 'gm'), '/')).getTime()

      // 時間若小於 2099/12/31 23:59:00
      if (time < 4102415940000) {
        return true
      } else {
        return false
      }
    },
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.getProductsList(['page', this.currentPage])
    },
    // 篩選狀態時觸發
    statusOptions(e) {
      this.currentPage = 1
      let filtered = this.filtered
      let arr = ['isrtime', '']

      if (filtered.includes('1') && filtered.includes('0')) {
        arr.splice(1, 1, -1)
        // return -1
      } else if (filtered.includes('1')) {
        arr.splice(1, 1, 1) // return 1
      } else if (filtered.includes('0')) {
        arr.splice(1, 1, 0) // return 0
      } else {
        arr.splice(1, 1, -1)
        // return -1
      }
      this.getProductsList(arr)
    },
    // 篩選出貨狀態時觸發
    instock_statusOptions(e) {
      this.currentPage = 1
      let filteredInstockStatus = this.filteredInstockStatus
      let arr = ['instock_status', '']

      if (filteredInstockStatus.includes('1') && filteredInstockStatus.includes('0')) {
        arr.splice(1, 1, -1)
        // return -1
      } else if (filteredInstockStatus.includes('1')) {
        arr.splice(1, 1, 1) // return 1
      } else if (filteredInstockStatus.includes('0')) {
        arr.splice(1, 1, 0) // return 0
      } else {
        arr.splice(1, 1, -1)
        // return -1
      }
      this.getProductsList(arr)
    },
    // 篩選標題時觸發
    search(e) {
      if (this.searchTitle === this.searchTitleBak) return
      this.currentPage = 1
      this.getProductsList(['query', this.searchTitle])

      this.searchTitleBak = this.searchTitle
    },
    // 調整庫存數量時
    inventoryChange(e) {
      let value = e.target.valueAsNumber
      if (isNaN(value)) value = ''
      else if (value < 0) value = 0
      this.filteredInventory = value
    },
    // 確定庫存數量時
    inventoryConfirm() {
      if (this.filteredInventory === '') {
        // 若為空 傳-1 (無搜尋)
        this.filteredInventory = -1
      }
      this.getProductsList(['inventory', this.filteredInventory])
      this.inventoryTooltip = false
    },
    // 取消庫存數量時
    inventoryCancel() {
      this.filteredInventory = ''
      this.getProductsList(['inventory', ''])
      this.inventoryTooltip = false
    },
    // 批次操作 公開/隱藏
    batchPublic(isrtime) {
      this.$axios({
        url: 'front/store/product/uProductRtime.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this._selectedToStr,
          isrtime: isrtime, // 0 隱藏 1 公開
        },
      }).then((res) => {
        if (res.data.Success) {
          this.getProductsList(['page', this.currentPage])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 批次操作 刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/product/dProduct.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.getProductsList(['page', this.currentPage])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 批次操作 已到貨
    bathInstock() {
      const loading = this.$vs.loading({
        target: this.$refs.instockBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/product/uProductInstockNotice.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.instockNotice_dialog = false // 關閉dialog
          this.getProductsList(['page', this.currentPage])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.productsList.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.productsList.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.productsList.length; i++) {
          this.selected.push(this.productsList[i].spno)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
    // 3.1.1.1 商品列表-讀取
    getProductsList(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (arr) routeQuery[arr[0]] = arr[1] // 新增進物件
      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '') {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      return this.$axios({
        url: 'front/store/product/getProductList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          isrtime: routeQuery.isrtime === undefined ? -1 : routeQuery.isrtime,
          instock_status: routeQuery.instock_status === undefined ? -1 : routeQuery.instock_status,
          page: routeQuery.page ? routeQuery.page : 1,
          title: routeQuery.query ? routeQuery.query : '',
          snum: routeQuery.inventory === undefined ? -1 : routeQuery.inventory,
          type: routeQuery.collections ? routeQuery.collections : '',
          tag: routeQuery.tags ? routeQuery.tags : '',
          manu: routeQuery.vendors ? routeQuery.vendors : '',
        },
      }).then((res) => {
        if (res.data.Success) {
          this.productsList = res.data.Data
          this.pagination = res.data.Page
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 商品列表標籤的操作
    tagFilter(tag, num) {
      let tagArr = []
      tagArr = tag.split(',')
      let firstTag = tagArr.shift()
      // 回傳第一個
      if (num === 1) return firstTag
      // 回傳剩餘的數量
      else if (num === 2) return '+' + tagArr.length
      // 回傳剩餘的內容
      else if (num === 3) return tagArr.join(',')
    },
    // 3.1.4.1 讀取標籤列表
    getTagList() {
      return this.$axios({
        url: 'front/store/tag/getTagList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.tags = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 3.1.5.1 讀取廠商列表
    getVendorList() {
      return this.$axios({
        url: 'front/store/manufacturer/getManufacturerList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.vendors = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
        return res.data.Success
      })
    },
    // 3.1.3.1 讀取分類列表
    getCollections() {
      return this.$axios({
        url: 'front//store/type/getTypeList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.collections = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min xl:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}

.section1 {
  width: 144px;
}

.section2 {
  width: 144px;
}
</style>
